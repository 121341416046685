<template>
  <div class="tooltip-sizes">
    <div class="tooltip-sizes__item">
      <slot/>
    </div>
    <div class="tooltip-sizes__hover">
      <div class="tooltip-sizes__sizes">
        <div
          v-for="(sizeItem, index) in sizesList"
          :key="`colorItem-${ index }`"
        >
          {{ sizeItem.value }}
        </div>
      </div>
      <nuxt-link
        :to="productUrl"
        class="tooltip-sizes__link"
      >
        Смотреть все
        <img src="~/assets/img/svg/common/arrow.svg"/>
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ComputedRef } from 'vue';

interface ISizeItem {
  value: String
}
interface IProps {
  sizes: ISizeItem[],
  productUrl: any
}

const props = withDefaults(defineProps<IProps>(),{
  sizes: () => []
});

const sizesList: ComputedRef<ISizeItem[]> = computed(() => {
  return [...(props.sizes || [])].splice(0, 4)
});
</script>

<style scoped lang="scss">
.tooltip-sizes {
  position: relative;

  &:hover {
    .tooltip-sizes__hover {
      display: block;
    }
  }
}
.tooltip-sizes__hover {
  display: none;
  position: absolute;
  right: 0;
  bottom: calc(100% + 20px);
  z-index: 2;

  filter: drop-shadow(6px 4px 31px rgba(0, 0, 0, 0.05));
  background-color: white;
  border-radius: 10px 10px 0 10px;
  padding: 16px;
  box-sizing: border-box;

  min-width: 125px;

  &:after {
    content: "";
    position: absolute;
    top: 100%; right: 0;
    width: 14px; height: 14px;
    background-color: white;
    pointer-events: none;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    transform: translateY(-1px);
  }
  &:before {
    content: "";
    position: absolute;
    top: 100%; left: 0; right: 0;
    height: 20px;
  }
}
.tooltip-sizes__sizes {
  display: flex;
  flex-direction: column;

  & > * {
    display: flex;
    flex-direction: column;

    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #333333;
    white-space: nowrap;

    &:after {
      content: "";
      width: 100%;
      height: 1px;
      opacity: 0.1;
      background-color: black;
      margin: 8px 0;
    }
  }
}
.tooltip-sizes__link {
  display: flex;
  align-items: center;
  height: 15px;

  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #333333;
  opacity: 0.5;

  img {
    width: 10px;
    height: 10px;
    margin-left: 5px;
    object-fit: contain;
    transform: rotate(180deg);
  }
  &:hover {
    opacity: 1;
  }
}

@media (max-width: 1023px) {
  .tooltip-sizes__hover {
    display: none!important;
  }
}
</style>
